import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

import {Post} from '../../common/types/post';

const Wrapper = styled(Link)`
  display: block;
  /* background-color: rgba(255, 255, 255, 0.05); */
  background-color: rgba(0, 0, 0, 0.25);
  /* width: 33.333%; */
  /* padding: 15px; */
  transition-duration: 100ms;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  box-shadow: 0 0 1em #000;

  &:hover {
    /* background-color: rgba(255, 255, 255, 0.1); */
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const ImageBlock = styled.div`
  overflow: hidden;

  img {
    width: 100%;
    display: block;
    /* object-fit: cover; */
    transform: scale(1);
    transition: transform .2s;
  }

  ${Wrapper}:hover & img {
    transform: scale(1.2);
  }
`;
const PostContent = styled.div`
  padding: 1em;

  > * + * {
    margin-top: 0.5em;
  }
`;
const PostDate = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8em;
`;
const Heading = styled.h3`
  color: rgba(255, 255, 255, 0.75);
  font-family: 'Pirata One', serif;
  font-size: 32px;
`;

interface Props {
  post: Post;
}

export const PostPreviewBlock: React.FunctionComponent<Props> = (props) => {
  const {post} = props;
  const {frontmatter} = post;
  const matchResult = frontmatter.date.match(/(\d{4})-(\d{2})-(\d{2})/);
  if (!matchResult) return null;
  const [_, year, month] = matchResult;
  const postPath = `/blog/${year}/${month}${frontmatter.path}`;

  return (
    <Wrapper
      to={postPath}
    >
      <ImageBlock>
        <img
          src={frontmatter.thumbnail.childImageSharp.fluid.src}
          className="lazyload"
          alt="Thumbnail"
        />
      </ImageBlock>

      <PostContent>
        <div>
          <PostDate>{frontmatter.date}</PostDate>
        </div>

        <div>
          <Heading>{frontmatter.title}</Heading>
        </div>
      </PostContent>
    </Wrapper>
  );
};