import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql, navigate, useStaticQuery, Link } from 'gatsby';
import {Helmet} from 'react-helmet';

import {Post} from '../common/types/post';

import { WideLayout, LayoutContentBlock } from '../components/layout';
import { QueryData } from '../common/types/query-data';
import {PostPreviewBlock} from '../components/post-preview-block';

const Wrapper = styled.div`
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;
const PostsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;

  > li {
    display: flex;
  }
`;

// const Blog = (props: {data: QueryData}) => {
const Blog = () => {
  // const { data } = props;
  // const { post } = data;
  // const { frontmatter, fields } = post;
  // const defaultPostPath = `/blog/${frontmatter.date}${fields.slug}`;

  // useEffect(() => {
  //   navigate(defaultPostPath);
  // });

  const {site, posts}: QueryData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      posts: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { fileAbsolutePath: { regex: "/posts/" } }
      ) {
        edges {
          node {
            id
            frontmatter {
              path
              date(formatString: "YYYY-MM-DD")
              title
              thumbnail {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <WideLayout>
      <Helmet>
        <title>{site.siteMetadata.title} | Blog</title>
      </Helmet>

      <Wrapper
        style={{
          backgroundImage: `url('/images/screenshot_3840x2160_2020-07-19_12-49-26.jpg')`
        }}>
        <div style={{background: 'linear-gradient(transparent, hsl(270, 33%, 25%, .5), #171717)', padding: '150px 0 10em'}}>
          <LayoutContentBlock>
              <PostsList>
                {posts && posts.edges.map(edge => {
                  const {frontmatter} = edge.node;
                  const matchResult = frontmatter.date.match(/(\d{4})-(\d{2})-(\d{2})/);
                  if (!matchResult) return null;

                  const [_, year, month] = matchResult;
                  const postPath = `/blog/${year}/${month}${frontmatter.path}`;

                  return (
                    <li key={edge.node.id}>
                      <PostPreviewBlock post={edge.node} />
                    </li>
                  );
                })}
              </PostsList>
          </LayoutContentBlock>
        </div>
      </Wrapper>
    </WideLayout>
  );
};

// export const query = graphql`
//   query {
//     post: markdownRemark(fileAbsolutePath: {regex: "/posts/"}) {
//       fields {
//         slug
//       }
//       frontmatter {
//         date(formatString: "YYYY/MM")
//       }
//     }
//   }
// `;

export default Blog;
